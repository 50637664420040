import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedRestaurant: {},
    restaurants: [],
    allergenGuide: "",
    kidsMenu: "",
    cocktailFlavours: [],
    cocktailFilters: [],
    selectedMenu: {},
    selectedItem: {},
    selectedItemTheme: '',
    showDietaryFilters: false,
    allergenList: [],
    selectedAllergens: [],
    tags: [],
    selectedTags: [],
    filtersUpdated: "",
    selectedFilterCount: 0,
    orderMap: {
      brunch: "orderBottomless",
      drinks: "orderDrinks",
      dinner: "orderDinner",
      lunch: "orderLunch",
      breakfast: "orderBreakfast",
    },
    selectedOrder: false,
    campaignCard: false,
    takeawayMenu: "",
    noCalorieMenu: "",
    showItemPopup: false,
  },
  mutations: {
    changeRestaurant (state, restaurant) {
      state.selectedRestaurant = restaurant;
    },
    updateRestaurantList (state, restaurants) {
      state.restaurants = restaurants;
    },
    setAllergenGuide (state, guide) {
      state.allergenGuide = guide;
    },
    setKidsMenu (state, menu) {
      state.kidsMenu = menu;
    },
    setCocktailFlavours (state, flavours) {
      state.cocktailFlavours = flavours;
    },
    setCocktailFilters (state, filters) {
      state.cocktailFilters = filters;
    },
    setSelectedMenu (state, menu, filtered = false) {
      //add show attribute
      // filtered attribute might be redundant now, since filtering on 
      // this stage did not work, and now is done on individual item basis
      if (!filtered) {
        for (let i = 0; i < menu.fields.sections.length; i++) {
          const section = menu.fields.sections[i];
          for (let j = 0; j < section.fields.menuItems.length; j++) {
            const item = section.fields.menuItems[j];
            item.show = true;          
          }
        }
      }
           
      state.selectedMenu = menu;
    },
    setSelectedItem(state, item) {
      state.selectedItem = item;
    },
    setSelectedItemTheme(state, theme) {
      state.selectedItemTheme = theme;
    },
    setShowItemPopup(state, show) {
      state.showItemPopup = show;

      if (show) {
        document.querySelector('html').style.overflowY = 'hidden';
        document.querySelector('html').style.touchAction = 'none';
      } else {
        document.querySelector('html').style.overflowY = 'initial';
        document.querySelector('html').style.touchAction = 'initial';

      }
    },
    setShowDietaryFilters(state, show) {
      state.showDietaryFilters = show;

      if (show) {
        document.querySelector('html').style.overflowY = 'hidden';
        document.querySelector('html').style.touchAction = 'none';
      } else {
        document.querySelector('html').style.overflowY = 'initial';
        document.querySelector('html').style.touchAction = 'initial';
      }
    },
    setAllergens(state, allergens) {
      state.allergenList = allergens.map(el => {
        return el.fields.title
      });
    },
    setSelectedAllergens(state, allergens) {
      state.selectedAllergens = allergens;
    },
    setTags (state, tags) {
      state.tags = tags.map(el => {
        return {
          name: el.name,
          id: el.sys.id
        }
      });
    },
    setSelectedTags (state, tags) {
      state.selectedTags = tags;
    },
    setFiltersUpdated (state, timestamp) {
      state.filtersUpdated = timestamp;
    },
    changeOrder (state, order) {
      state.selectedOrder = order;
    },
    setCampaignCard (state, card) {
      state.campaignCard = {
        title: card.fields.title,
        body: card.fields.body,
        link: card.fields.link,
        colour: card.fields.colour,
        ctaText: card.fields.linkButtonText
      }
    },
    setTakeawayMenu (state, menu) {
      state.takeawayMenu = menu;
    },
    setNoCalorieMenu (state, menu) {
      state.noCalorieMenu = menu;
    },
    setSelectedFilterCount (state, count) {
      state.selectedFilterCount = count;
    }
  },
  getters: {
    getAllCocktails (state) {
      if (!state.selectedRestaurant)
        return false;
      
      this.getters.getCocktailSection.fields.menuItems
    },
    getCocktailSection (state) {
      if (!state.selectedRestaurant)
        return false;

      for (let index = 0; index < state.selectedMenu.fields.sections.length; index++) {
        if (state.selectedMenu.fields.sections[index].sys.contentType.sys.id == 'cocktailSection'){
          return index;
        }
      }
    },
    getSelectedItem (state) {
      if (!state.selectedItem)
        return false;

      return state.selectedItem;
    }
  },
  actions: {
    applyMenuItemFilters({commit}, [tags,allergens]) {
      // sets selected tags and allergens, since for some reason
      // watchers do not work with quick successions, we will group these into action
      // and also commit unix timestamp to filters updated varialbe
      // and watch for its changes in the menu item component to proceed with filtering
      commit('setSelectedTags', tags);  
      commit('setSelectedAllergens', allergens);
      commit('setFiltersUpdated', + new Date());  
      commit('setSelectedFilterCount', tags.length + allergens.length);
    },
  },
  modules: {
  }
})
