// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/arrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".restaurant-selector__subtitle{text-transform:uppercase;font-weight:800;font-size:1.2em;text-align:center;padding:0 1.2rem;margin:.5em auto}.restaurant-selector__input-select{margin-bottom:25px;position:relative}.restaurant-selector__input-select select{width:100%;border:4px solid #01c5b5;margin:0;padding:.5rem 2.25rem .5rem 1rem;font-family:cubano,sans-serif;font-size:1em;line-height:1.5;color:#01c5b5;background-color:#fff;border-radius:.25rem;cursor:pointer;outline:0;-webkit-appearance:none;-moz-appearance:none;appearance:none}.restaurant-selector__input-select:after{content:\"\";position:absolute;right:0;bottom:0;display:block;width:50px;height:50px;background:#01c5b5 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;background-size:50%;border-top-right-radius:5px;border-bottom-right-radius:5px;pointer-events:none}@media screen and (max-width:800px){.restaurant-selector__menu-buttons{flex-wrap:wrap}}.restaurant-selector__menu-buttons,.restaurant-selector__menu-placeholders{display:flex;gap:20px;align-content:center;justify-content:center;flex-wrap:wrap}.restaurant-selector .btn{filter:grayscale(0);transition:filter .2s ease-in;flex-basis:calc(50% - 10px)}@media screen and (max-width:800px){.restaurant-selector .btn{flex-basis:100%}}.restaurant-selector .btn--disabled{filter:grayscale(1)}", ""]);
// Exports
module.exports = exports;
