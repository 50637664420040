import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Menu - Turtle Bay UK'
    }
  },
  {
    path: '/:slug',
    name: 'Menu',
    component: () => import(/* webpackChunkName: "about" */ '../views/Menu.vue'),
    meta: {
      title: 'Dashboard'
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeResolve((to, from, next) => {
  if (to.name == "Menu") {

    // if accessed the url with the menu directly, mutate the selected restaurant in the store
    
    let slug = to.params.slug;

    let [selected] = store.state.restaurants.filter(el => {
        return el.fields.slug == slug;
    });

    to.meta.title = `${selected.fields.title} Menu - Turtle Bay UK`;

    if (!selected) {
      return next('/')
    }

    store.commit('changeRestaurant', selected);
  
    // set menu order
    let order = to.query.order

    if (order) {
      store.commit('changeOrder', order);
    } else {
      store.commit('changeOrder', false);
    }

    // check if there is a filter

    let tags = to.query.tags;

    if (tags) {
      if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(() => {
          store.dispatch("applyMenuItemFilters", [
            tags.split(','),
            []
          ]);
        }, 1);
      } else {
          document.addEventListener("DOMContentLoaded", () => {
            store.dispatch("applyMenuItemFilters", [
              tags.split(','),
              []
            ]);
          });
      } 
    } 
    
  
  }
  next();
});

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title ;
  }
});

export default router
