<template>
  <div class="home">
    <!-- <Header /> -->

    <div class="container">
      <RestaurantSelector />
      <CampaignCard />
      <OrderSelector />
      <PDFLinks />
      <Socials />
    </div>

    <!-- <Footer /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import RestaurantSelector from "@/components/RestaurantSelector.vue";
import OrderSelector from "@/components/OrderSelector.vue";
import Socials from "@/components/Socials.vue";
import PDFLinks from "@/components/PDFLinks.vue";
import CampaignCard from "@/components/CampaignCard.vue";
// import Header from "@/components/Header.vue";
// import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    RestaurantSelector,
    OrderSelector,
    PDFLinks,
    Socials,
    CampaignCard,
    // Header,
    // Footer
  },
};
</script>

<style lang="scss">
.home {
  background: #f2f2ef;
  background-image: url(../assets/images/textures/general-texture_web.jpg);
  background-size: 100% auto;
  background-repeat: repeat-y;
  // border-top: 6px solid #ffd700;

  > .container {
    padding-top: 70px;
  }
}
</style>