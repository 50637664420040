// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/images/textures/tb-teal-texture-1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "html{scroll-behavior:smooth;overflow-x:initial}.btn{width:100%;margin-bottom:12px;display:inline-block;padding:1.2rem 1.5rem;color:#fff;background-color:#01c5b5;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat-y;background-size:100% auto;background-position-y:55%;text-decoration:none;font-family:cubano,sans-serif;text-align:center;position:relative;z-index:4;line-height:1.1;font-size:1.2em;border:0}.btn--disabled{cursor:not-allowed;opacity:.6}.container{max-width:1400px;margin:0 auto;padding:50px 20px}body{overflow-x:initial;font-size:20px;font-family:cubano,sans-serif;color:#2f465f;margin:0}p{font-family:paralucent,sans-serif}", ""]);
// Exports
module.exports = exports;
