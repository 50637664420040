<template>
  <div class="restaurant-selector">
    <h2 class="restaurant-selector__subtitle">SELECT A RESTAURANT TO CHECK OUT OUR MENU!</h2>
    <div class="restaurant-selector__input-select">
      <select name="restaurants" id="restaurants" @change="changeRestaurant">
        <option value="" hidden>Choose a restaurant</option>
        <option v-for="restaurant in restaurantList" v-bind:key="restaurant.fields.slug" :value="restaurant.fields.slug">{{restaurant.fields.title}}</option>
      </select>
    </div>
    <div class="restaurant-selector__menu-selector">
        <div class="restaurant-selector__menu-placeholders" v-if="!selectedRestaurant.fields">
          <span class="btn btn--disabled">Explore the menu</span>
          <!-- <router-link 
            title="Kids menu"
            :to="kidsMenu" 
            :class="{
              'btn': true,
          }">Kids menu</router-link>
          <span class="btn btn--disabled">Take Away</span> -->
        </div>
        <div class="restaurant-selector__menu-buttons" v-if="selectedRestaurant.fields">
          <a
          id="explore-menu-button"
          :title="mainMenu.fields.displayTitle"
          :href="getMenuLink(mainMenu).url"
          :target="getMenuLink(mainMenu).target" 
          :class="{
            'btn': true,
            }">Explore the menu</a>

          <a
            v-if="festiveMenu"
            id="explore-festive-menu-button"
            :title="mainMenu.fields.displayTitle"
            :href="getMenuLink(festiveMenu).url"
            :target="getMenuLink(festiveMenu).target" 
            :class="{
              'btn': true,
              'order-selector__button--red': true
              }">{{festiveMenu.fields.displayTitle}}</a>

          <!-- kids menu button -->
          <!-- <a
            title="Kids menu"
            :href="kidsMenu"
            target="_blank"
            :class="{
              'btn': true,
            }">Kids menu</a> -->
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RestaurantSelector',
  data () {
    return {
      mainMenu: false,
      festiveMenu: false,
    }
  },
  computed: {
    restaurantList () {
      return this.$store.state.restaurants
    },
    selectedRestaurant() {
      return this.$store.state.selectedRestaurant
    },
    kidsMenu() {
      return this.$store.state.kidsMenu; 
    },
  },
  mounted () {
  },
  watch: {
    'selectedRestaurant' : function () {
      [this.mainMenu] = this.selectedRestaurant.fields.tierMenus.filter((el) => {
        return el.fields.displayTitle.toLowerCase() == "main menu";
      });
      [this.festiveMenu] = this.selectedRestaurant.fields.tierMenus.filter((el) => {
        return el.fields.sticky;
      });
    }
  },
  methods: {
    changeRestaurant (e) {
      let [val] = this.restaurantList.filter(el => {
        return el.fields.slug == e.target.value;
      });
      this.$store.commit('changeRestaurant', val);
    },
    getMenuLink (menu) {
      return menu.fields.pdfOnlyMenu ? 
        { 
          url: "https://"+menu.fields.menuPdf.fields.file.url, 
          target: "_blank" 
        } 
        : { url: "/menu/"+this.selectedRestaurant.fields.slug + `?menu=${menu.fields.slug}`, target: "_self" };
    }
  }
}
</script>

<style lang="scss">
  .restaurant-selector {
    
    &__subtitle {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 1.2em;
      text-align: center;
      padding: 0 1.2rem;
      margin: 0.5em auto;
    }

    &__input-select {
      margin-bottom: 25px;
      position: relative;

      select {
        width: 100%;
        border: 4px solid #01C5B5;
        margin: 0;
        padding: 0.5rem 2.25rem 0.5rem 1rem;
        font-family: "cubano",sans-serif;
        font-size: 1em;
        line-height: 1.5;
        color: #01C5B5;
        background-color: #fff;
        border-radius: 0.25rem;
        cursor: pointer;
        outline: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 50px;
        height: 50px;
        background: #01C5B5 url(../assets/images/arrow.svg) center no-repeat;
        background-size: 50%;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        pointer-events: none;
      }
    }

    &__menu-buttons {
      @media screen and (max-width: 800px) {
        flex-wrap: wrap;
      }
    }
 
    &__menu-buttons,
    &__menu-placeholders {
      display: flex;
      gap: 20px;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    .btn {
      filter: grayscale(0);
      transition: filter .2s ease-in;
      flex-basis: calc(50% - 10px);

      @media screen and (max-width: 800px) {
        flex-basis: 100%;
      }

      &--disabled {
        filter: grayscale(1);
      }
    }
  }
</style>