<template>
  <div class="socials">
    <h3 class="socials__title">Find us on</h3>
    <a :href="selectedRestaurant.fields ? selectedRestaurant.fields.tripAdvisorLink : ''" :class="{
        'socials__link' : true,
        'socials__link--disabled' : !selectedRestaurant.fields  
      }" target="_blank" rel="noopener noreferrer" title="Trip advisor">
        <img src="../assets/images/tripadvisor.svg" alt="Trip advisor logo">
      </a>
      <a href="https://www.instagram.com/turtlebayuk/" target="_blank" class="socials__link" title="Instagram" rel="noopener noreferrer">
        <img src="../assets/images/instagram.svg" alt="Instagram logo">
      </a>
  </div>
</template>

<script>
export default {
  name: 'Socials',
  computed: {
    selectedRestaurant() {
      return this.$store.state.selectedRestaurant
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>


<style lang="scss">
  .socials {
    text-align: center;
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &__title {
      text-transform: uppercase;
      margin-bottom: 25px;
      margin-top: 25px;
      flex-basis: 100%; 
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 15px 15px;
      text-decoration: none;
      padding: 10px 0; 
      text-transform: uppercase;
      color: #2f465f;
      padding: 10px;
      border-radius: 2px;

      &--disabled {
        opacity: 0.7;
        pointer-events: none;
      }
    }
  }
</style>
