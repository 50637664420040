// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/textures/tb-pink-texture-2.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/textures/tb-orange-texture-2.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/images/textures/tb-teal-texture-2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".campaign-card{padding:20px;position:relative;margin:2em 0 3em;text-align:center}.campaign-card--pink{background:#ef69b9;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat-y;background-size:100% auto;background-position-y:15%}.campaign-card--pink .campaign-card__title,.campaign-card--pink b{color:#fff}.campaign-card--orange{background:#ff7900;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:repeat-y;background-size:100% auto;background-position-y:15%}.campaign-card--orange .campaign-card__title,.campaign-card--orange h3,.campaign-card--orange h4{color:#fff}.campaign-card--orange b{font-weight:700;color:#fff}.campaign-card--blue{background:#00a0df;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-repeat:repeat-y;background-size:100% auto;background-position-y:15%}.campaign-card__title{margin-top:0;text-align:center}.campaign-card__cta{position:absolute;bottom:-20px;left:50%;transform:translatex(-50%);display:inline-block;padding:10px;background:#002e5f;color:#fff;text-transform:uppercase;text-decoration:none}", ""]);
// Exports
module.exports = exports;
