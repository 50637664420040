import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyload from 'vue-lazyload'

const axios = require('axios').default;
const contentful = require("contentful");

let apiKey, env, cacheDecider;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const envParam = urlParams.get('env');

console.log(envParam);

if (window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('tbmenudev.halo.limited') > -1) {
  if (envParam == "dev") {
    apiKey = "mIU5TkVKUL2K0RORkHT3oTdyqjNlqNMlkFw0qxb2-lU" //dev
    env = "staging";
  } else {
    apiKey = "GKnrQYWLH0V6uUdESEAyqo0LUOEQ14vvYpH5uChzSJ4" //live
    env = "master";
  }
  cacheDecider = false;
} else {
  apiKey = "GKnrQYWLH0V6uUdESEAyqo0LUOEQ14vvYpH5uChzSJ4" //live
  env = "master";
  cacheDecider = true;
}

const client = contentful.createClient({
  environment: env,
  space: "m584fpq61mt9",
  accessToken: apiKey
});

Vue.config.productionTip = false

if (cacheDecider) {
  // try to get cached data
  axios.get("https://tb-menu-proxy.herokuapp.com/get_data").then( function (response) {
    init_vue(response.data);
  }).catch(error => {
    console.log(error);
    // grab data the old way
    // grab initial global data
    let promises = [
      client.getTags(), 
      client.getEntries({ content_type: "allergen" }), 
      client.getEntries({ content_type: "restaurant", include: 10, order: 'fields.title' }),
      client.getAsset('4QyvwjIjd5HZS2pLHbw48t'), // allergen guide
      client.getAsset('7KKgjSncdi91aMweeRSdlF'), // kids menu
      client.getEntries({ content_type: "cocktailFlavours" }), 
      client.getEntries({ content_type: "campaignCard" }), 
      // client.getAsset('N5a5ILJDi9WN2A7gRZRkc'), // takeaway menu
      client.getAsset('1ZiTHDqvo6jDWu9LSub05i'), // no calorie menu
    ];
  
    Promise.all(
      promises.map(promise => Promise.resolve( promise ).catch( _=>_ ))
    ).then(function (result) {
      let tags = result[0].items;
      let allergens = result[1].items;
      let restaurants = result[2].items;
      let allergenGuide = result[3].fields ? `https:${result[3].fields.file.url}` : "";
      let kidsMenu = result[4].fields ? `https:${result[4].fields.file.url}` : "";
      let cocktailFlavours = result[5].items;
      let campaignCard = result[6].items? result[6].items[0] : false;
      let noCalorieMenu = result[7].fields ? `https:${result[7].fields.file.url}`: "";
  
      let cachedData = {
        tags,
        allergens,
        restaurants,
        allergenGuide,
        kidsMenu,
        cocktailFlavours,
        campaignCard,
        noCalorieMenu
      }
  
      init_vue(cachedData);
    });
  })
} else {
  // grab data the old way
    // grab initial global data
    let promises = [
      client.getTags(), 
      client.getEntries({ content_type: "allergen" }), 
      client.getEntries({ content_type: "restaurant", include: 10, order: 'fields.title' }),
      client.getAsset('4QyvwjIjd5HZS2pLHbw48t'), // allergen guide
      client.getAsset('7KKgjSncdi91aMweeRSdlF'), // kids menu
      client.getEntries({ content_type: "cocktailFlavours" }), 
      client.getEntries({ content_type: "campaignCard" }), 
      // client.getAsset('N5a5ILJDi9WN2A7gRZRkc'), // takeaway menu
      client.getAsset('1ZiTHDqvo6jDWu9LSub05i'), // no calorie menu
    ];
  
    Promise.all(
      promises.map(promise => Promise.resolve( promise ).catch( _=>_ ))
    ).then(function (result) {
      let tags = result[0].items;
      let allergens = result[1].items;
      let restaurants = result[2].items;
      let allergenGuide = result[3].fields ? `https:${result[3].fields.file.url}` : "";
      let kidsMenu = result[4].fields ? `https:${result[4].fields.file.url}` : "";
      let cocktailFlavours = result[5].items;
      let campaignCard = result[6].items? result[6].items[0] : false;
      let noCalorieMenu = result[7].fields ? `https:${result[7].fields.file.url}` : "";
  
      let cachedData = {
        tags,
        allergens,
        restaurants,
        allergenGuide,
        kidsMenu,
        cocktailFlavours,
        campaignCard,
        noCalorieMenu
      }
  
      init_vue(cachedData);
  });
}


function init_vue(cachedData) {
  Vue.use(VueLazyload);
  
  // initiate the vue app after calls were made
  new Vue({
    router,
    store,
    data: {
    },
    created() {
      this.$store.commit('updateRestaurantList', cachedData.restaurants);
      this.$store.commit('setAllergenGuide', cachedData.allergenGuide);
      this.$store.commit('setKidsMenu', cachedData.kidsMenu);
      this.$store.commit('setCocktailFlavours', cachedData.cocktailFlavours);
      this.$store.commit('setAllergens', cachedData.allergens);
      this.$store.commit('setTags', cachedData.tags);
      if (cachedData.campaignCard) {
        this.$store.commit('setCampaignCard', cachedData.campaignCard);
      }
      // this.$store.commit('setTakeawayMenu', cachedData.takeawayMenu);
      this.$store.commit('setNoCalorieMenu', cachedData.noCalorieMenu);
    },
    mounted() {

      setTimeout(() => {
        document.querySelector('.menu-loading').classList.remove('menu-loading');
      }, 500)

      this.$Lazyload.$on('loaded', function ({ el }) {
        // console.log(el, src, $parent);
        let section = el.closest('.menu-section'); 
        section.classList.remove('menu-section--inactive');
        if (section.nextElementSibling )
          section.nextElementSibling.classList.remove('menu-section--inactive');
      })

      // if (document.readyState == "complete") {
      //   window.parent.postMessage({height: document.documentElement.scrollHeight}, '*');
      // } else {
      //   document.onreadystatechange = () => {
      //     if (document.readyState == "complete") {
      //       window.parent.postMessage({height: document.documentElement.scrollHeight}, '*');
      //     }
      //   }
      // }

      // // create an Observer instance
      // const resizeObserver = new ResizeObserver(entries => {
      //     window.parent.postMessage({height: entries[0].target.clientHeight}, '*');
      // });

      // // start observing a DOM node
      // resizeObserver.observe(document.body)
    },
    render: h => h(App)
  }).$mount('#menu-app')
}


