<template>
  <div class="order-selector">
    <h2 class="order-selector__subtitle">Customise your menu - tell us what you're interested in</h2>
    <div class="order-selector__button-wrapper">
      <router-link 
        id="order-bottomless"
        :to="selectedRestaurant.fields ? `${selectedRestaurant.fields.slug}?menu=${mainMenu.fields.slug}&order=brunch` : ''"
        :class="{
          'order-selector__button': true,
          'order-selector__button--pink': true,
          'order-selector__button--disabled': !selectedRestaurant.fields
        }">Bottomless brunch</router-link>
      <router-link
        id="order-drinks"
        :to="selectedRestaurant.fields ? `${selectedRestaurant.fields.slug}?menu=${mainMenu.fields.slug}&order=drinks` : ''"
        :class="{
          'order-selector__button': true,
          'order-selector__button--blue': true,
          'order-selector__button--disabled': !selectedRestaurant.fields
        }">Just drinks</router-link>
      <router-link
        id="order-dinner"
        :to="selectedRestaurant.fields ? `${selectedRestaurant.fields.slug}?menu=${mainMenu.fields.slug}&order=dinner` : ''"
        :class="{
          'order-selector__button': true,
          'order-selector__button--orange': true,
          'order-selector__button--disabled': !selectedRestaurant.fields
        }">Dinner</router-link>
      <router-link
        id="order-lunch"
        :to="selectedRestaurant.fields ? `${selectedRestaurant.fields.slug}?menu=${mainMenu.fields.slug}&order=lunch` : ''"
        :class="{
          'order-selector__button': true,
          'order-selector__button--navy': true,
          'order-selector__button--disabled': !selectedRestaurant.fields
        }">Brunch & Lunch</router-link>
      <router-link 
        id="vegan-menu"
        :to="selectedRestaurant.fields ? `${selectedRestaurant.fields.slug}?menu=${mainMenu.fields.slug}&tags=vegan` : ''"
        :class="{
          'order-selector__button': true,
          'order-selector__button--green': true,
          'order-selector__button--disabled': !selectedRestaurant.fields
        }">Vegan options</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderSelector',
  computed: {
    selectedRestaurant() {
      return this.$store.state.selectedRestaurant
    },
    mainMenu() {
      return this.selectedRestaurant.fields? this.getMainMenu() : {}; 
    }
  },
  mounted () {
  },
  methods: {
    getMainMenu: function () {
      let [menu] = this.selectedRestaurant.fields.tierMenus.filter(el => {
        return el.fields.displayTitle.toLowerCase() == "main menu";
      });
      return menu;
    },
  }
}
</script>

<style lang="scss">
  .order-selector {

    &__subtitle {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 1.2em;
      text-align: center;
      padding: 0 1.2rem;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    &__button {
      display: block;
      padding: 1.2rem 0;
      color: #fff;
      font-size: 1.2em;
      text-transform: uppercase;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      filter: grayscale(0);
      transition: filter .2s ease-in;
      // flex: 1 0 calc(20% - 80px);
      // flex: 1 1 0px;

      $n: 5; // number of columns
      $gap: 20px; // margin pixels

      margin: 0;
      margin-top: 15px;
      margin-bottom: 15px;

      flex: 0 0 calc(50% - 40px);

      @media screen and (min-width: 801px) {
        &:nth-child(2n+2) {
          margin-left: 20px;
        }
      }

      @media screen and (max-width: 800px) {
        flex: 1 1 100%;
      }
      
      &--disabled {
        pointer-events: none;
        opacity: 0.7;
        filter: grayscale(1);
      }

      &-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }

      &--pink {
        background: #EA67B5;
        background-image: url(../assets/images/textures/tb-pink-texture-2.png);
        background-repeat: repeat-y;
        background-size: 100% auto;
        background-position-y: 22%;
      }

      &--blue {
        background: #02C0B0;
        background-image: url(../assets/images/textures/tb-teal-texture-1.png);
        background-repeat: repeat-y;
        background-size: 100% auto;
        background-position-y: 55%;
      }

      &--yellow {
        background: #F9D100;
        background-image: url(../assets/images/textures/tb-yellow-texture-2.png);
        background-repeat: repeat-y;
        background-size: 100% auto;
        background-position-y: 70%;
      }

      &--orange {
        background: #F97601;
        background-image: url(../assets/images/textures/tb-orange-texture-3.png);
        background-repeat: repeat-y;
        background-size: 100% auto;
        background-position-y: 75%;
      }

      &--red {
        background: #E20032;
        background-image: url(../assets/images/textures/tb-red-texture-3.png);
        background-repeat: repeat-y;
        background-size: 100% auto;
        background-position-y: 75%;
      }

      &--darkblue {
        background: #4F8C99;
        background-image: url(../assets/images/textures/tb-pink-texture-2.png);
        background-repeat: repeat-y;
        background-size: 100% auto;
        background-position-y: 35%;
      }

      &--green {
        background: #52A939;
        background-image: url(../assets/images/textures/tb-green-texture-2.png);
        background-repeat: repeat-y;
        background-size: 100% auto;
        background-position-y: 15%;
      }

      &--navy {
        background: #2f465f;
        background-image: url(../assets/images/textures/tb-navy-texture-2.png);
        background-repeat: repeat-y;
        background-size: 100% auto;
        background-position-y: 35%;
      }
    }
  }
</style>