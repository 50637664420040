var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-selector"},[_c('h2',{staticClass:"order-selector__subtitle"},[_vm._v("Customise your menu - tell us what you're interested in")]),_c('div',{staticClass:"order-selector__button-wrapper"},[_c('router-link',{class:{
        'order-selector__button': true,
        'order-selector__button--pink': true,
        'order-selector__button--disabled': !_vm.selectedRestaurant.fields
      },attrs:{"id":"order-bottomless","to":_vm.selectedRestaurant.fields ? ((_vm.selectedRestaurant.fields.slug) + "?menu=" + (_vm.mainMenu.fields.slug) + "&order=brunch") : ''}},[_vm._v("Bottomless brunch")]),_c('router-link',{class:{
        'order-selector__button': true,
        'order-selector__button--blue': true,
        'order-selector__button--disabled': !_vm.selectedRestaurant.fields
      },attrs:{"id":"order-drinks","to":_vm.selectedRestaurant.fields ? ((_vm.selectedRestaurant.fields.slug) + "?menu=" + (_vm.mainMenu.fields.slug) + "&order=drinks") : ''}},[_vm._v("Just drinks")]),_c('router-link',{class:{
        'order-selector__button': true,
        'order-selector__button--orange': true,
        'order-selector__button--disabled': !_vm.selectedRestaurant.fields
      },attrs:{"id":"order-dinner","to":_vm.selectedRestaurant.fields ? ((_vm.selectedRestaurant.fields.slug) + "?menu=" + (_vm.mainMenu.fields.slug) + "&order=dinner") : ''}},[_vm._v("Dinner")]),_c('router-link',{class:{
        'order-selector__button': true,
        'order-selector__button--navy': true,
        'order-selector__button--disabled': !_vm.selectedRestaurant.fields
      },attrs:{"id":"order-lunch","to":_vm.selectedRestaurant.fields ? ((_vm.selectedRestaurant.fields.slug) + "?menu=" + (_vm.mainMenu.fields.slug) + "&order=lunch") : ''}},[_vm._v("Brunch & Lunch")]),_c('router-link',{class:{
        'order-selector__button': true,
        'order-selector__button--green': true,
        'order-selector__button--disabled': !_vm.selectedRestaurant.fields
      },attrs:{"id":"vegan-menu","to":_vm.selectedRestaurant.fields ? ((_vm.selectedRestaurant.fields.slug) + "?menu=" + (_vm.mainMenu.fields.slug) + "&tags=vegan") : ''}},[_vm._v("Vegan options")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }