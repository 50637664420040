<template>
  <div
    :class="['campaign-card', `campaign-card--${card.colour}`]"
    v-if="card"
  >
    <h2 class="campaign-card__title">{{ card.title }}</h2>
    <div class="campaign-card__body" v-html="card.body"></div>
    <a :href="card.link" target="_blank" class="campaign-card__cta">{{ card.ctaText }}</a>
  </div>
</template>

<script>
export default {
  name: "CampaignCard",
  props: {},
  data: () => {
    return {
    
    };
  },
  components: {},
  computed: {
    card() {
      return this.$store.state.campaignCard; 
    }
  },
  mounted() {

  },
  methods: {},
};
</script>

<style lang="scss">
  .campaign-card {
    padding: 20px;
    position: relative;
    margin: 2em 0 3em;
    $self: &;
    text-align: center;

    &--pink {
      background: #ef69b9;
      background-image: url(../assets/images/textures/tb-pink-texture-2.png);
      background-repeat: repeat-y;
      background-size: 100% auto;
      background-position-y: 15%;
      
      #{ $self }__title {
        color: #fff;
      }

      b {
        color: #fff;
      }

      p {

      }
    }

    &--orange {
      background: #ff7900;  
      background-image: url(../assets/images/textures/tb-orange-texture-2.png);
      background-repeat: repeat-y;
      background-size: 100% auto;
      background-position-y: 15%;


      #{ $self }__title,
      h3, h4 {
        color: #fff;
      }

      b {
        font-weight: bold;
        color: #fff;
      }

      p {

      }
    }

    &--blue {
      background: #00a0df;  
      background-image: url(../assets/images/textures/tb-teal-texture-2.png);
      background-repeat: repeat-y;
      background-size: 100% auto;
      background-position-y: 15%;

    }

    &__title {
      margin-top: 0;
      text-align: center;
    }

    &__cta {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translatex(-50%);
      display: inline-block;
      padding: 10px;
      background: #002e5f;
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
    }

  }
</style>