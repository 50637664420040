<template>
  <div class="pdf-links">

    <a id="main-menu-pdf" :href="mainMenu.fields ? 'https:' + mainMenu.fields.menuPdf.fields.file.url : ''" 
    :class="{
      'pdf-links__link' : true,
      'pdf-links__link--disabled' : !(mainMenu.fields && mainMenu.fields.menuPdf.fields.file.url)
    }" target="_blank" rel="noopener noreferrer">Download PDF</a>
    <!-- <a :href="kidsMenu" class="pdf-links__link" target="_blank" rel="noopener noreferrer">Kids Menu</a> -->
    <!-- <a :href="takeawayMenu" class="pdf-links__link" target="_blank" rel="noopener noreferrer">Takeaway Menu</a> -->
    <a :id="menu.fields.displayTitle.toLowerCase().replaceAll(' ', '-') + '-pdf'" v-for="menu in pdfMenus" v-bind:key="menu.sys.id" :href="`https://${menu.fields.menuPdf.fields.file.url}`" class="pdf-links__link" target="_blank" rel="noopener noreferrer">{{menu.fields.displayTitle}}</a>
    <a id="no-calorie-menu-pdf" :href="noCalorieMenu" class="pdf-links__link" target="_blank" rel="noopener noreferrer">No Calorie Menu</a>
    <a id="allergen-guide-pdf" :href="allergenGuide" class="pdf-links__link" target="_blank" rel="noopener noreferrer">Allergens guide</a>
  </div>
</template>

<script>
export default {
  name: 'OrderSelector',
  data () {
    return {
      pdfMenus: [],
    }
  },
  computed: {
    selectedRestaurant() {
      return this.$store.state.selectedRestaurant
    },
    mainMenu() {
      return this.selectedRestaurant.fields? this.getMainMenu() : {}; 
    },
    allergenGuide() {
      return this.$store.state.allergenGuide; 
    },
    kidsMenu() {
      return this.$store.state.kidsMenu; 
    },
    noCalorieMenu() {
      return this.$store.state.noCalorieMenu; 
    }
  },
  mounted () {
  },
  watch: {
    'selectedRestaurant': function () {
      return this.pdfMenus = this.selectedRestaurant.fields.tierMenus.filter((el) => {
        return el.fields.pdfOnlyMenu && !el.fields.sticky;
      }); 
    },
  },
  methods: {
    getMainMenu: function () {
      let [menu] = this.selectedRestaurant.fields.tierMenus.filter(el => {
        return el.fields.displayTitle.toLowerCase() == "main menu";
      });
      return menu;
    },
  }
}
</script>

<style lang="scss">
  .pdf-links {
    text-align: center;

    &__link {
      display: inline-block;
      margin: 15px;
      text-decoration: none;
      border-bottom: 2px solid #67B2E9;
      padding: 10px 0; 
      text-transform: uppercase;
      color: #2f465f;

      &--disabled {
        opacity: 0.7;
        pointer-events: none;
      }
    }
  }
</style>